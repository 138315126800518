/* eslint-disable react/jsx-no-target-blank, react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSharedState} from '../../../../app/modules/common/SharedStateProvider'
import Permissions from '../../../../app/routing/Permissions'
import {getConfig} from '../../../../app/pages/student/services/StudentProfileService'

export function AsideMenuMain() {
  const intl = useIntl()
  const {user, isContentWriter, isBranchLevelLogin} = useSharedState()

  const [isStudent, setIsStudent] = useState(false)
  const [config, setConfig] = useState<any>()

  useEffect(() => {
    if (user) {
      console.log('user', user)
      if (user.ProfileId) {
        if (typeof user.ProfileId === 'string') {
          // eslint-disable-next-line no-self-assign
          user.ProfileId = user.ProfileId
        } else {
          user.ProfileId = user.ProfileId[0]
        }
        setIsStudent(true)
      }
    }
  }, [user])

  useEffect(() => {
    function fetchData() {
      if (!user?.ProfileId) return
      getConfig(+user.ProfileId).then((resp) => {
        setConfig(resp?.data)
      })
    }

    if (isStudent) {
      fetchData()
    }
  }, [isStudent])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/social/speedometer2.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {config?.requiresLor && (
        <AsideMenuItem
          to={`/student/${user?.ProfileId}/edit/lor`}
          icon='/media/icons/duotune/social/lor.svg'
          title={'LOR Questionnaire'}
        />
      )}
      {config?.requiresSop && (
        <AsideMenuItem
          to={`/student/${user?.ProfileId}/edit/sop`}
          icon='/media/icons/duotune/social/sop.svg'
          title={'SOP Questionnaire'}
        />
      )}
      {config?.requiresEssay && (
        <AsideMenuItem
          to={`/student/${user?.ProfileId}/edit/essay`}
          icon='/media/icons/duotune/general/gen005.svg'
          title={'Essays'}
        />
      )}
      {isStudent && (
        <AsideMenuItem
          to={`/student/${user?.ProfileId}/university-suggestions`}
          icon='/media/icons/duotune/general/mortarboard.svg'
          title={'University Suggestions'}
        />
      )}
      {isStudent && (
        <AsideMenuItem
          to={`/student/${user?.ProfileId}/downloads`}
          icon='/media/menu/cloud-arrow-down-fill.svg'
          title={'Downloads'}
        />
      )}
      <AsideMenuItem
        to={`/change-password`}
        icon='/media/menu/change-password.svg'
        title={'Change Password'}
      />
      {!isStudent && !isContentWriter && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/users'
            icon='/media/icons/duotune/social/people-fill.svg'
            title={intl.formatMessage({id: 'MENU.USER.MANAGEMENT'})}
            fontIcon='bi-people'
          >
            {user?.Permission?.includes(Permissions.Can_Create_User) && (
              <AsideMenuItem to='/users/create' title='Create new User' hasBullet={true} />
            )}
            {user?.Permission?.includes(Permissions.Can_Create_Student) && (
              <AsideMenuItem
                to='/users/create/student'
                title='Create new Student'
                hasBullet={true}
              />
            )}
            {user?.Permission?.includes(Permissions.Can_List_Users) && (
              <AsideMenuItem to='/users/' title='All Users' hasBullet={true} />
            )}
            {user?.Permission?.includes(Permissions.Can_List_Students) && (
              <AsideMenuItem to='/students/' title='All Students' hasBullet={true} />
            )}
          </AsideMenuItemWithSub>

          {user?.Permission?.includes(Permissions.Can_Update_University) && (
            <AsideMenuItem
              to='/universities'
              icon='/media/icons/duotune/general/mortarboard.svg'
              title={'University Management'}
            />
          )}

          {user?.Permission?.includes(Permissions.Can_Update_Term) && (
            <AsideMenuItem to='/terms' icon='/media/misc/sunglasses.svg' title={'Terms'} />
          )}

          {user?.Permission?.includes(Permissions.Can_Change_Bll) && (
            <AsideMenuItem
              to='/bulk-actions'
              icon='/media/svg/misc/ui-checks-grid.svg'
              title={'Bulk Actions'}
            />
          )}

          {user?.Permission?.includes(Permissions.Can_Update_Permissions) && (
            <AsideMenuItem
              to='/permissions'
              title='Permission Manager'
              icon='/media/svg/misc/person-bounding-box.svg'
            />
          )}
          {user?.Permission?.includes(Permissions.Can_Send_Bulk_Message) && (
            <AsideMenuItem
              to='/bulk-message'
              icon='/media/menu/chat-square-dots.svg'
              title={'Bulk Messaging'}
            />
          )}

          {user?.roles?.includes('SuperAdmin') &&
            user?.Permission?.includes(Permissions.Can_Generate_Report) && (
              <AsideMenuItem
                to='/reports'
                icon='/media/menu/graph-up-arrow.svg'
                title={'Reports'}
              />
            )}

          {user?.Permission?.includes(Permissions.Can_Generate_Report) && (
            <AsideMenuItem
              to={`/alumni-list`}
              icon='/media/icons/duotune/general/mortarboard.svg'
              title={'Alumni List'}
            />
          )}

          {user?.Permission?.includes(Permissions.Can_Generate_Report) && (
            <AsideMenuItem
              to='/all-reports-new'
              icon='/media/menu/bar-chart.svg'
              title={'Generate Statistics'}
            />
          )}

          {user?.Permission?.includes(Permissions.Can_Update_Suggestion_Partner) && (
            <AsideMenuItem
              to='/view-partnered-suggestions'
              icon='/media/menu/person-workspace.svg'
              title={'Partner Suggestions'}
            />
          )}
          <AsideMenuItemWithSub
            to='/users'
            icon='/media/menu/gear-wide-connected.svg'
            title={'General Management'}
            fontIcon='bi-people'
          >
            {user?.Permission?.includes(Permissions.Can_Update_Country) && (
              <AsideMenuItem
                to='/countries'
                icon='/media/flags/united-states.svg'
                title={'Countries'}
              />
            )}
            {user?.Permission?.includes(Permissions.Can_Update_Country) && (
              <AsideMenuItem to='/banners' icon='/media/menu/images.svg' title={'Banners'} />
            )}
            {user?.Permission?.includes(Permissions.Can_Manage_Files) && (
              <AsideMenuItem
                to='/manage-common-files'
                icon='/media/menu/folder-plus.svg'
                title={'File Manager'}
              />
            )}
            {!isBranchLevelLogin && user?.Permission?.includes(Permissions.Can_List_Partners) && (
              <AsideMenuItem
                to='/partners'
                icon='/media/menu/person-workspace.svg'
                title={'Partners'}
              />
            )}

            {user?.Permission?.includes(Permissions.Can_Manage_Programs) && (
              <AsideMenuItem
                to='/courses'
                icon='/media/icons/duotune/finance/fin002.svg'
                title={'Programs'}
              />
            )}
            {user?.Permission?.includes(Permissions.Can_Update_University) && (
              <AsideMenuItem
                to='/universities/edit/rankings'
                icon='/media/menu/list-nested.svg'
                title={'University Course Ranking'}
              />
            )}
          </AsideMenuItemWithSub>
        </>
      )}
    </>
  )
}
