import React, {createContext, useContext, useState, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {getAllCountries} from '../../pages/countries-management/CountryService'
import {ICountry} from '../../pages/countries-management/ICountry'
import {ICourse, IFieldOfStudy, IndianUniversity} from '../../pages/student/models'
import {actions, ICommonState} from '../../pages/student/redux/CommonReduxActions'
import {getCommonData, parseJwt} from '../../modules/common/CommonService'
import {ITerm} from '../../pages/term-management/ITerm'
import {getAllTerms} from '../../pages/term-management/TermsService'
import {UserModel} from '../auth/models/UserModel'

export const getCourseOptions = (courses: ICourse[]) =>
  courses?.map((x) => ({
    label: x.name,
    value: x.id,
    children: x.fieldOfStudies.map((c) => ({
      label: c.name,
      value: c.id,
      children: c.specializations.map((s) => ({
        label: s.name,
        value: s.id,
      })),
    })),
  }))

export interface DropdownOption {
  label: string
  value: any
  children?: any[]
}

export interface SharedStateModel {
  courses: DropdownOption[]
  universities: DropdownOption[]
  fieldsOfStudy: DropdownOption[]
  terms: ITerm[]
  countries: ICountry[]
  colleges: DropdownOption[]
  user: UserModel | undefined | any
  isBranchLevelLogin: boolean
  isSuperAdmin: boolean
  isAdmin: boolean
  isBranchAdmin: boolean
  isStudent: boolean
  isContentWriter: boolean
}

const SharedStateContext = createContext<SharedStateModel>({
  courses: [],
  universities: [],
  fieldsOfStudy: [],
  terms: [],
  countries: [],
  colleges: [],
  user: undefined,
  isBranchLevelLogin: false,
  isSuperAdmin: false,
  isAdmin: false,
  isBranchAdmin: false,
  isStudent: false,
  isContentWriter: false,
})

const SharedStateProvider: React.FC = ({children}) => {
  const [fieldsOfStudy, setFieldsOfStudy] = useState<DropdownOption[]>([])
  const [universities, setUniversities] = useState<DropdownOption[]>([])
  const [courses, setCourseOptions] = useState<DropdownOption[]>([])
  const [colleges, setCollegeOptions] = useState<DropdownOption[]>([])
  const [terms, setTerms] = useState<ITerm[]>([])
  const [countries, setCountries] = useState<ICountry[]>([])
  const [user, setUser] = useState<UserModel>()
  const token = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  useEffect(() => {
    if (token) {
      setUser(parseJwt(token as string))
    }
  }, [token])

  const dispatch = useDispatch()
  const cachedData: ICommonState = useSelector((state: RootState) => state.common)

  function populateUnis(universities: IndianUniversity[]) {
    const uniOptions = universities.map((x) => ({label: x.name, value: x.id}))
    setUniversities(uniOptions)
  }

  function populateFieldsOfStudy(fos: IFieldOfStudy[]) {
    const fosOptions = fos.map((x) => ({
      label: x.name,
      value: x.id,
      children: x.specializations.map((s) => ({value: s.id, label: s.name})),
    }))
    setFieldsOfStudy(fosOptions)
  }

  const isSuperAdmin = user?.roles?.includes('SuperAdmin') || false
  const isAdmin = (!isSuperAdmin && user?.roles?.includes('OverallAdmin')) || false
  const isBranchAdmin = (!isAdmin && user?.roles?.includes('BranchAdmin')) || false
  const isBranchLevelLogin = (!isBranchAdmin && user?.roles?.includes('BranchLevelLogin')) || false
  const isStudent = (!isBranchLevelLogin && user?.roles?.includes('Student')) || false
  const isContentWriter = user?.roles?.includes('ContentWriter') || false

  const value: SharedStateModel = {
    courses: courses,
    universities: universities,
    fieldsOfStudy: fieldsOfStudy,
    terms: terms,
    countries: countries,
    colleges: colleges,
    user: user,
    isBranchLevelLogin,
    isSuperAdmin,
    isAdmin,
    isBranchAdmin,
    isStudent,
    isContentWriter,
  }

  useEffect(() => {
    //get data
    let isSubscribed = true
    const fetchData = async () => {
      //get data from server and cache it
      const {
        data: {universities, colleges, fos, courses},
      } = await getCommonData()
      if (isSubscribed) {
        populateUnis(universities.data)
        dispatch(actions.cacheIndianUniversities(universities.data))

        populateFieldsOfStudy(fos.data)
        dispatch(actions.cacheFieldsOfStudy(fos.data))

        setCourseOptions(getCourseOptions(courses))
        dispatch(actions.cacheCourses(courses))

        setCollegeOptions(
          colleges.data
            .filter((x: any) => x.isVerified)
            .map((x: any) => ({label: x.name, value: x.name}))
        )
        dispatch(actions.cacheColleges(colleges.data))
      }
    }
    if (!cachedData.colleges.length) {
      fetchData()
    } else {
      const {universities, fieldsOfStudy, courses, colleges} = cachedData
      populateUnis(universities)
      populateFieldsOfStudy(fieldsOfStudy)
      setCourseOptions(getCourseOptions(courses))
      setCollegeOptions(colleges.map((x) => ({label: x.name, value: x.name})))
    }

    async function getTermsAndCountries() {
      const {data: terms} = await getAllTerms()
      if (terms && isSubscribed) {
        setTerms(terms.filter((x) => x.isActive))
      }

      const {data: countries} = await getAllCountries()
      if (countries && isSubscribed) {
        setCountries(countries.filter((x) => x.isActive))
      }
    }
    getTermsAndCountries()
    // cancel subscription to useEffect
    return () => {
      isSubscribed = false
    }
    //eslint-disable-next-line
  }, [])

  return <SharedStateContext.Provider value={value}>{children}</SharedStateContext.Provider>
}

export {SharedStateContext, SharedStateProvider}

export function useSharedState() {
  return useContext(SharedStateContext)
}
